<template>
  <!-- 一级路由 -->
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>
