// 顶级分类，防止在请求的数据回来之前页面显示空白，所以先将顶级分类定义成常量数据，请求的数据返回后直接替换即可
export const topCategory = [
  '家用',
  '食品',
  '衣服',
  '母婴',
  '清洁',
  '严选',
  '数码',
  '运动',
  '杂货'
]